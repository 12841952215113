.line {
    height: 4px;
    width: 100px;
    border-width: 0;
    color: red;
    background-color: red
}

.l-2 {
    height: 2px;
    width: 100px;
    border-width: 0;
    color: red;
    background-color: red
}

.navbar {
    text-transform: uppercase
}

.carousel-txt {
    position: absolute;
    top: 50%;
    left: 20%;
    z-index: 999;
    color: #fff
}

.coloured {
    color: #e712bc
}

.carousel-item img {
    height: 500px
}

a {
    color: #000
}

a:hover {
    text-decoration: underline !important
}

.media-body a:hover {
    padding-left: 10px;
    margin-top: 10px
}

.es.active,
.es:hover {
    background-color: #e712bc;
    color: #000;
    cursor: pointer
}

.projects {
    overflow: hidden;
    position: relative
}

.projects img {
    width: 100%;
    height: 300px
}

.project-description span {
    display: block;
    font-size: 12px;
    font-weight: 700;
    margin: 0 0 50px;
    text-transform: uppercase
}

.project-description a,
.project-description p {
    visibility: hidden
}

.project-description:hover,
.projects:hover .project-description {
    top: 0;
    opacity: .8
}

.projects:hover .project-description a,
.projects:hover .project-description p,
.project-description:hover a,
.project-description:hover p {
    visibility: visible
}

.project-description {
    bottom: -80%;
    display: block;
    height: 100%;
    left: 0;
    background-color: #fff;
    position: absolute;
    -webkit-transition: all .3s ease 0s;
    transition: all .3s ease 0s;
    width: 100%;
    z-index: 1
}

.project-description h5 {
    color: #0062ad
}

.project-description a {
    background-color: #e712bc;
    color: #fff;
    padding: 5px
}

.prices {
    list-style-type: none;
    padding: 0;
    margin: 0;
    padding-top: 16px
}

.prices li {
    padding: 10px;
    border: 1px solid #999
}

.prices .head {
    background-color: #f0c;
    color: #fff
}

.carousel-txt a,
.prices a {
    background-color: #f0c;
    color: #fff;
    padding: 10px;
    margin: 10px
}

.carousel-txt a:hover,
.prices a:hover {
    background-color: red
}

.togle {
    background-color: red
}

.service,
.projects,
.contact-me {
    box-shadow: 2px 2px 0 rgba(0, 0, 0, .1)
}

.service:hover,
.projects:hover,
.contact-me:hover {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .1), 0 2px 4px 0 rgba(0, 0, 0, .1)
}

.navbar-toggler:focus {
    outline: none !important
}

.navbar-toggler:active,
.navbar-toggler:hover {
    outline: none !important
}

.footer-social-link .fa {
    color: #3f3
}

.footer-social-link .fa:hover {
    color: #00f;
    background-color: #f0c
}

footer {
    color: #fff
}

@media(max-width:600px) {
    .carousel-item img {
        height: fit-content
    }

    .carousel-txt {
        top: 30%
    }

    .es {
        width: 100%
    }

    .prices {
        margin: 10px
    }

    .project-description {
        bottom: -75%
    }
}

.go_up {
    margin-bottom: 90px
}

.navbar-toggler-icon {
    background-color: #666;
    margin-bottom: 4px;
    display: block;
    height: 3px
}

textarea:hover,
input:hover,
textarea:active,
input:active,
textarea:focus,
input:focus,
button:focus,
button:active,
button:hover,
label:focus,
.btn:active,
.btn.active {
    outline: 0 !important;
    -webkit-appearance: none;
    box-shadow: none !important
}