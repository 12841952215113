.centered {
    position: relative;
    top: 50%;
    left: 50%;
    /* bring your own prefixes */
    transform: translate(-40%, -50%);
}

.bg-secondary2 {
    background-color: #f1f1f1;
}

.line {
    height: 4px;
    width: 100px;
    border-width: 0;
    color: red;
    background-color: red;
}

.l-2 {
    height: 2px;
    width: 100px;
    border-width: 0;
    color: red;
    background-color: red;

}

.coloured {
    color: #e712bc;
}

.carousel-item img {
    height: 500px;
}

a:hover {
    color: #e712bc;
    text-decoration: none;
}

.navbar-toggler:focus {
    outline: none !important;
}

.note {
    color: #ff0000;
}

.excercise {
    display: block;
    padding-top: 10px;
    font-family: sans-serif;
    font-size: 20px;
    color: #00ff00;
}

.navbar-toggler:active,
.navbar-toggler:hover {
    outline: none !important;
}

.footer-social-link .fa {
    color: #33ff33;
}



.navbar-toggler-icon {
    background-color: white;
    margin-bottom: 4px;
    display: block;
    height: 5px;
}

.comment-section {
    margin: 10px;
}

#comments {
    /*background-color: #cccccc; */
    /* display: none; */
}

.comment-section .media-body {
    background-color: #f8f9fa;
    margin-bottom: 2px;
}

.comment-section .media-body .fa-comment {
    color: #00ff00;
}

@media (max-width: 400px) {

    .ads-section-large {
        display: none;
    }
}


.link-white a {
    color: white;
}

.link-black a {
    color: black;
}

.btn-file {
    position: relative;
    overflow: hidden;
}

.btn-file input[type=file] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;
    background: white;
    cursor: inherit;
    display: block;
}

#drop-area {
    height: 200px;
    width: 100%;
    border: 3px dashed #6c757d !important;
}

#drop-area.over {
    border: 3px dashed #0c0 !important;
}

.row {
    position: relative !important;
    margin: auto !important;
}

textarea:hover,
textarea:active,
textarea:focus,
button:focus,
button:active,
button:hover,
label:focus,
.btn:active,
.btn.active,
.form-select:active,
.form-select:focus,
.form-select:hover,
input:not([type="checkbox"]):focus,
input:not([type="checkbox"]):active,
input:not([type="checkbox"]):hover {
    outline: 0px !important;
    -webkit-appearance: none;
    box-shadow: none !important;
}

@media (max-width:500px) {
    .container-small {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}

/*From Home page*/
a:hover {
    text-decoration: none !important;
    color: black;
}

#go_up {
    z-index: 2147483647 !important;
    position: relative;
}

@media (max-width: 786px) {
    .tags {
        z-index: 0 !important;
    }
}

.skeleton-img {
    height: 150px;
    max-width: 200px;
    min-width: 150px;
}

.skeleton {

    margin-bottom: 0.5rem;
    animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
    0% {
        background-color: hsl(200, 20%, 80%);
    }

    100% {
        background-color: hsl(200, 20%, 95%);
    }
}

.skeleton-text {
    width: 100%;
    height: 1rem;
}

.skeleton-text-md {
    width: 75%;
    height: 1rem;
}

.skeleton-text-sm {
    width: 100%;
    height: 0.5rem;
}

.btn-close:after {
    position: absolute;
    font-family: 'Font Awesome 5 Free';
    content: 'X';
    font-weight: 900;
}